<template>
    <v-container
        class="container-juego mb-0"
        style="display: grid; grid-template-rows: 95% 5%"
    >
        <iframe
            v-if="getGameAddress"
            style="position: relative; bottom:0; width: 100%; height: 100%; padding:0; margin:0;"
            :src="getGameAddress"
            frameborder="0"
            scrolling="no"
            allowtransparency="true"
        />

        <!--  <iframe
    src="https://irisglobalback.digitabilities.com/juego/index.html"
    scrolling="auto"
    allowfullscreen=""
    width="100%"
    height="100%"
    frameborder="0"
/>-->

        <nextPostBtn
            :url-next-post="nextPost"
            class="button-next"
        />
    </v-container>
</template>

<script>
import postMixin from '@/mixins/postMixin'
import nextPostBtn from '@/components/posts/nextPostBtn'

export default {
    name: 'JuegoMisiones',
    components: { nextPostBtn },
    mixins: [postMixin],
    computed: {
        getGameAddress() {
            let folder = ''
            let src = ''
            const myGroup = this.$store.getters.getMyGroupRoot

            if (myGroup === 3) {
                folder = 'juegoIA'
            } else if (myGroup === 2 || myGroup === 4) {
                folder = 'juegoIG'
            }

            console.log('folder', folder)

            if (folder) {
                src = `https://irisglobalback.digitabilities.com/juego/${folder}/index.html`
            }
            return src
        }
    },
    methods: {
        resizeIframe(obj) {
            obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px'
        }
    }
}
</script>

<style scoped>
.container-juego {
  display: grid;
  /*justify-content: center;
  justify-items: center;*/
  bottom: 0;
  left: 0;
  width: 94vw;
  max-width: 1050px;
  height: 75vh;
  padding: 0;
  margin: 0;

  /*border: 1px solid #ff375f;*/
}

@media (min-width: 1264px) {
  .container-juego {
    max-width: 100% !important;
  }
}

.button-next{
  margin-right: 25px;
}

@media (min-width: 500px) {
  .button-next{
    margin-right: 20%;
  }
}

</style>
